import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from 'react-router-dom';


export default function Navbar() {
    const navigate = useNavigate();
    const [bgColor, setBgColor] = useState('bg-[rgba(31,110,119,0.21)]');
    const [isOffCanvasOpen, setIsOffCanvasOpen] = useState(false);

    useEffect(function () {
        window.addEventListener('scroll', function () {
            if (this.window.scrollY > 0) {
                setBgColor('bg-[#1B2D34]');
            } else {
                setBgColor('bg-[rgba(31,110,119,0.21)]');
            }
        });
    }, []);

    return (
        <>
            <nav className={`${bgColor} fixed w-full z-20 top-0 start-0 rounded-b-xl`}>
                <div className="max-w-screen-xl flex items-center justify-between mx-auto p-4">
                    <button
                        type="button"
                        className="inline-flex items-center w-5 h-5 justify-center text-sm text-maingreen rounded-lg md:hidden bg-transparent"
                        onClick={() => setIsOffCanvasOpen(true)}
                    >
                        <svg className="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                            <path stroke="#00D595" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
                        </svg>
                    </button>
                    <div className="w-[40%] hidden md:block">
                        <ul className="flex items-center space-x-8">
                            <li className="hover:text-maingreen cursor-pointer ">
                                <NavLink className={({ isActive }) => {
                                    return isActive ? "text-maingreen" : "";
                                }} to="/">Home
                                </NavLink>
                            </li>
                            <li className="hover:text-maingreen cursor-pointer">
                                <NavLink className={({ isActive }) => {
                                    return isActive ? "text-maingreen" : "";
                                }} to="/categories">Categories
                                </NavLink>
                            </li>
                            <li className="hover:text-maingreen cursor-pointer">
                                <NavLink className={({ isActive }) => {
                                    return isActive ? "text-maingreen" : "";
                                }} to="/about">About
                                </NavLink>
                            </li>
                            <li className="hover:text-maingreen cursor-pointer">
                                <NavLink className={({ isActive }) => {
                                    return isActive ? "text-maingreen" : "";
                                }} to="/help">Help
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className="w-[45%] flex items-center justify-start md:justify-center">
                        <span className="text-white jaldi-bold text-xl md:text-3xl">Torrent Flicks</span>
                    </div>
                    <div className="w-[40%] flex justify-end">
                        <button onClick={() => navigate('search')} type="button" className="text-black bg-maingreen focus:outline-none rounded-lg px-4 py-2 text-sm md:text-base text-center inline-flex items-center">
                            <svg className="md:me-2 me-[0.2rem] md:w-4 md:h-4 w-3 h-3 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <path stroke="#0B0B0B" strokeLinecap="round" strokeWidth="2" d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z" />
                            </svg>
                            Search Movies
                        </button>
                    </div>
                </div>
            </nav>


            <div className={`fixed top-0 left-0 z-30 h-full w-64 bg-[#1B2D34] transform ${isOffCanvasOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out`}>
                <div className="p-4">
                    <span className="jaldi-bold ">Menu</span>
                    <button
                        type="button"
                        className="text-maingreen focus:outline-none float-end"
                        onClick={() => setIsOffCanvasOpen(false)}
                    >
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                        </svg>
                    </button>
                    <ul className="mt-8 space-y-4">
                        <li className="inline-flex">
                            <NavLink className={({ isActive }) => {
                                return isActive ? "text-maingreen" : "text-white";
                            }} to="/" onClick={() => setIsOffCanvasOpen(false)}>
                                Home
                            </NavLink>
                        </li>
                        <li>
                            <NavLink className={({ isActive }) => {
                                return isActive ? "text-maingreen" : "text-white";
                            }} to="/categories" onClick={() => setIsOffCanvasOpen(false)}>Categories
                            </NavLink>
                        </li>
                        <li>
                            <NavLink className={({ isActive }) => {
                                return isActive ? "text-maingreen" : "text-white";
                            }} to="/about" onClick={() => setIsOffCanvasOpen(false)}>About
                            </NavLink>
                        </li>
                        <li>
                            <NavLink className={({ isActive }) => {
                                return isActive ? "text-maingreen" : "text-white";
                            }} to="/help" onClick={() => setIsOffCanvasOpen(false)}>Help
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
}
