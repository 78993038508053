
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import About from "./pages/About";
import { Routes, Route, useLocation } from 'react-router-dom';
import Help from "./pages/Help";
import Category from "./pages/Categories";
import Search from "./pages/Search";
import SingleMovie from "./pages/SingleMovie";
import { useEffect, useState } from "react";
import Loader from "./components/InfinityLoader";
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import SearchCategories from "./pages/CategoriesSearch";
import Error from "./pages/404";
import { Analytics } from "@vercel/analytics/react"

function App() {
  const [loading, setLoading] = useState(false)
  const location = useLocation();

  useEffect(function () {
    setLoading(true)
    const timer = setTimeout(() => {
      setLoading(false)
    }, 1000);
    return () => clearTimeout(timer)
  }, [location])

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Navbar />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='*' element={<Error />} />
            <Route path='/about' element={<About />} />
            <Route path='/help' element={<Help />} />
            <Route path='/categories' element={<Category />} />
            <Route path='/search' element={<Search />} />
            <Route path='/movie/:id' element={<SingleMovie />} />
            <Route path='/categories/:genre' element={<SearchCategories />} />
          </Routes>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
          <Analytics/>
        </>
      )}
    </>

  );
}

export default App;
